<template>
    <div>
        <Horizontal ref="Horizontal" v-if="$store.getters.currentPageVersion === 'Horizontal'" 
        :orderList="dataList"
         @scrollList="scrollList"
        @checkOrderDetail="checkOrderDetail"></Horizontal>
        <Vertical ref="Vertical" v-else
        :orderList="dataList"
         @scrollList="scrollList"
        @checkOrderDetail="checkOrderDetail"></Vertical>
    </div>
</template>

<script>
    import Horizontal from '@/components/Mine/GameOrderIndex/Horizontal/Index.vue'
    import Vertical from '@/components/Mine/GameOrderIndex/Vertical/Index.vue'
    
    import avater from '@/assets/menuList/icon_account.svg'
    
    export default {
        components:{
            Horizontal,
            Vertical
        },
        data() {
            return {
                currentPage:1,//当前页
                limit:10,
               dataList:[]
            }
        },
        created() {
            this.getList()
        },
        methods:{
            //获取游戏订单列表
            getList() {
                let requestData = {
                    user_id:this.$store.state.userId,
                    token:this.$store.state.token,
                    page:this.currentPage,
                    limit:this.limit
                }
                this.$api.pay.getPlatFormGameOrderHistory(requestData).then(res=> {
                    if (res.data.code === 200) {
                        this.dataList = this.dataList.concat(res.data.data)
                    }
                })
            },
            //滚动列表触发
            scrollList(e) {
                    this.$common.pagingRequest(e, this)
            },
           checkOrderDetail(item) {
               
               this.$router.push({
                   name: 'GameOrderDetail',
                   params: {
                       item:item
                   }
               })
           }
        }
    }
</script>

<style>
</style>
<template>
    <div class="all-page">
       <TopNav title="游戏订单" backPath="Mine"></TopNav>
       <div class="all-page-content-box" @scroll="scrollList" ref="scrollBox" v-if="orderList.length>0">
           <div class="order-item-box" v-for="(item, index) in orderList" :key="index" @click="checkOrderDetail(item)">
               <div class="order-item">
                   <div class="top-box">
                       <div class="avater-box">
                           <img :src="item.game_icon">
                       </div>
                       <div class="title-box">
                           <div class="title-st">
                              {{item.game_name}}
                           </div>
                           <div class="title-nd">
                               道具：{{item.goods_name}}
                           </div>
                       </div>
                   </div>
                   <div class="bottom-box">
                       <div class="time-text">
                           订单时间:{{item.create_time | changTimeType}}
                       </div>
                       <div class="money-box">
                           <img src="@/assets/common/money_orange.svg" >
                           <span>{{item.order_money | changMoney}}</span>
                       </div>
                   </div>
               </div>
           </div>
       </div>
       <BlankList v-else type="gameOrder"></BlankList>
    </div>
</template>

<script>
    import TopNav from "@/components/Layout/TopNav.vue"
    import BlankList from "@/components/Common/Vertical/BlankList.vue"
    export default {
        components: {
          TopNav,
          BlankList
        },
        props:{
          orderList:Array
        },
        data() {
            return {
             
            }
        },
        methods: {
            scrollList(e) {
                this.$nextTick(()=> {
                    this.$emit('scrollList', this.$refs.scrollBox)
                })
            },
           checkOrderDetail(item) {
               this.$emit('checkOrderDetail', item)
           }
        }
    }
</script>

<style scoped>
    .order-item-box {
        width: 100%;
        padding-bottom: 14px;
    }
    .order-item {
        padding: 11px 9px 5px 9px;
        background: #F4F4F4;
        border-radius: 9px;
    }
    .top-box {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .avater-box {
        width: 64px;
        height: 64px;
        border-radius: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        background-color: #ffffff;
    }
    .avater-box>img {
        width: 100%;
    }
    .title-box {
        padding-left: 13px;
        flex: 1;
    }
    .title-st,
    .title-nd {
        width: 137px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .title-st {
        font-size: 18px;
        font-weight: 400;
        line-height: 25px;
        color: #000000;
    }
    .title-nd {
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        color: #666666;
        padding-top: 3px;
    }
    .bottom-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 10px;
    }
    .time-text {
        font-size: 12px;
        font-weight: 400;
        color: #666666;
    }
    .money-box {
        display: flex;
        align-items: end;
    }
    .money-box>img {
        width: 6px;
    }
    .money-box>span {
        font-size: 18px;
        font-weight: 400;
        color: #FF7E00;
        line-height: 12px;
        padding-left: 1px;
    }
</style>
